import * as React from 'react';
import styled from 'styled-components';
import { PlayButton } from '../play-button/Index';

interface ITrack {
  number?: number;
  title: string;
  length: string;
  url: string;
}

export const TrackItem: React.FC<ITrack> = ({ number, title, length, url }) => {
  return (
    <StyledTrack>
      <PlayButton
        className="playbutton"
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.open(url, '_blank');
          }
        }}
      />
      {number ? <span className="track-number">{number}.</span> : null}
      <span className="track-title">{title}</span>
      <span className="track-length">{length}</span>
    </StyledTrack>
  );
};

const StyledTrack = styled.li`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  align-items: center;
  padding: 20px;
  color: #fff;
  border-bottom: solid 1px rgb(40, 40, 40);

  &:first-child {
    border-top: solid 1px rgb(40, 40, 40);
  }

  .track-number {
    margin-right: 10px;
  }
  .track-title {
    margin-right: 10px;
  }
  .track-length {
  }

  .playbutton {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    svg {
      width: inherit;
      height: inherit;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
