import { number } from 'prop-types';

export const calcIRR = (cashFlow: number[]): number => {
  const MAX_ITERATIONS = 100;
  const MAX_ERROR = 0.00001;
  let result: number = 0;
  let error: number = 1;
  let count: number = 0;

  do {
    const npv = calcNpv(result, cashFlow);
    const derivative = calcDerivativeNPV(result, cashFlow);
    const guessRate = result - npv / derivative;
    error = Math.abs(guessRate - result);
    result = guessRate;
    count++;
  } while (error > MAX_ERROR && count < MAX_ITERATIONS);

  return result;
};

const calcNpv = (rate: number, values: number[]): number => {
  return values?.reduce((total, value, index) => {
    return total + value / Math.pow(1 + rate, index);
  }, 0);
};

const calcDerivativeNPV = (rate: number, values: number[]): number => {
  return values?.reduce((total, value, index) => {
    return total - (index * value) / Math.pow(1 + rate, index + 1);
  }, 0);
};
