export enum CalcStatus {
  error = 'error',
  success = 'success'
}

export interface IResult {
  status: CalcStatus;
  message?: string;
  loan?: number;
  prMonth?: number;
  cost?: number;
  totalLoan?: number;
  effectiveRate?: number;
}
