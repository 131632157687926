import React from 'react';
import styled from 'styled-components';
import { routeConfig } from '../../../__config__/routes';
import { Button } from '../../ui/button/Button';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Section } from '../../layout/Section';
const Info: React.FC = () => {
  return (
    <InfoStyle>
      <Heading center tag="h3">
        Boliglånskalkulator
      </Heading>
      <Section style={{ paddingTop: '0' }} className="form-text">
        <Paragraph className="paragraph" center>
          Med boliglånskalkulatoren fra Nordea, kan du enkelt få oversikt over
          hvor mye lånet vil koste.
        </Paragraph>
        <Paragraph className="paragraph" center>
          Dette kan hjelpe deg med å ta gode valg som passer for din økonomi og
          livssituasjon.
        </Paragraph>
        <Paragraph className="paragraph" center>
          Ta kontakt om du ønsker mer informasjon, så vil en av Nordeas
          rådgivere hjelpe deg med et skreddersydd tilbud som passer akkurat
          deg.
        </Paragraph>
      </Section>
      <ButtonGroup style={{ justifyContent: 'center' }}>
        <Button
          className="button"
          type="primary"
          colorTheme="gold"
          as="a"
          href={routeConfig?.nordea_finansiering.url}
          target="_blank"
        >
          Kontakt en Nordea-rådgiver
        </Button>
      </ButtonGroup>
    </InfoStyle>
  );
};

const InfoStyle = styled.div`
  .buttongroup {
    justify-content: center;
  }
  .paragraph {
    width: 100%;
    max-width: 30em;
  }
  .button {
    text-align: center;
  }
`;

export default Info;
