import { CalcStatus, IResult } from './types';
import { effectiveRatePerYearWithFees } from './effectiveRate';
import { pmt } from './pmt';
import { APR } from './apr';
import { calcIRR } from './irr';

export interface IArgs {
  loan: number;
  nominalRate: number;
  durationYears: number;
  termsPrYear?: number;
  exemptionYears?: number;
  feeEstablish?: number;
  feePrTerm?: number;
}

export const calc = (args: IArgs): IResult => {
  const {
    loan,
    nominalRate,
    durationYears,
    exemptionYears,
    termsPrYear,
    feeEstablish,
    feePrTerm
  } = args;
  if (!loan) return { status: CalcStatus.error, message: 'Ugyldig lån!' };
  if (!durationYears)
    return { status: CalcStatus.error, message: 'Ugyldig nedbetalingstid!' };
  if (!nominalRate)
    return { status: CalcStatus.error, message: 'Ugyldig nominell rente!' };
  try {
    const monthPrYear = 12;
    const terms = termsPrYear ?? monthPrYear;
    const totalTerms = terms * (durationYears - (exemptionYears ?? 0));
    const prMonth = pmt(nominalRate / monthPrYear, totalTerms, -loan);
    const totalPrMonth = prMonth + (feePrTerm ?? 0);
    const total = totalPrMonth * totalTerms;

    return {
      status: CalcStatus.success,
      prMonth: Math.ceil(totalPrMonth),
      effectiveRate:
        effectiveRatePerYearWithFees({
          loan: loan - (feeEstablish ?? 0),
          paymentPrMonth: totalPrMonth,
          totalPeriods: totalTerms
        }) * 100,
      cost: Math.ceil(total - loan + (feeEstablish ?? 0)),
      loan,
      totalLoan: Math.ceil(total + (feeEstablish ?? 0))
    };
  } catch {}
  return { status: CalcStatus.error };
};
