import { pmt } from './pmt';
import { IArgs } from './calc';
import { CalcStatus } from './types';

interface IResult {
  status: CalcStatus;
  message?: string;
  total?: number;
  totalFees?: number;
  totalInterest?: number;
  cost?: number;
  plan?: IMonth[];
}

interface IMonth {
  index: number;
  loan: number;
  installment: number;
  interst: number;
  fee: number;
  remains: number;
  payment: number;
  period?: string;
}

export const plan = (args: IArgs): IResult => {
  const {
    loan,
    nominalRate,
    durationYears,
    exemptionYears,
    termsPrYear,
    feeEstablish,
    feePrTerm
  } = args;
  if (!loan) return { status: CalcStatus.error, message: 'Ugyldig lån!' };
  if (!durationYears)
    return { status: CalcStatus.error, message: 'Ugyldig nedbetalingstid!' };
  if (!nominalRate)
    return { status: CalcStatus.error, message: 'Ugyldig nominell rente!' };
  try {
    const startDate = 21;
    const terms = termsPrYear ?? 12;
    const totalTerms = terms * durationYears;
    const termRate = nominalRate / terms;
    const payment = pmt(termRate, totalTerms, loan);
    const totalFees = (feeEstablish ?? 0) + totalTerms * (feePrTerm ?? 0);
    let paid = 0,
      total = payment * totalTerms + totalFees,
      totalInterest = 0,
      plan: IMonth[] = [];
    const dt = new Date();
    if (dt.getDate() >= startDate) {
      dt.setMonth(dt.getMonth() + 1, startDate);
    } else {
      dt.setDate(startDate);
    }

    for (let i = 0; i < totalTerms + 1; i++) {
      const whatsLeft = loan - paid;
      if (roundNumber(whatsLeft) > 0) {
        const interest = whatsLeft * termRate;
        const installment = payment - interest;
        const ip = installment > whatsLeft ? whatsLeft : installment;
        totalInterest += interest;
        paid += ip;

        plan.push({
          index: i + 1,
          fee: feePrTerm ?? 0,
          installment: roundNumber(ip),
          interst: roundNumber(interest),
          loan: roundNumber(whatsLeft),
          payment: Math.ceil(payment + (feePrTerm ?? 0)),
          remains: Math.ceil(whatsLeft) - Math.ceil(ip),
          period: getPeriod(dt)
        });
        dt.setMonth(dt.getMonth() + 1);
      }
    }

    return {
      status: CalcStatus.success,
      total: Math.ceil(total),
      totalFees: Math.ceil(totalFees),
      totalInterest: Math.ceil(totalInterest),
      cost: Math.ceil(totalInterest + totalFees),
      plan
    };
  } catch {}
  return { status: CalcStatus.error };
};

const roundNumber = (value: number) => {
  return Math.round(value * 10) / 10;
};

const getPeriod = (date: Date) => {
  return `${date.getDate()} ${getMonthName(
    date.getMonth() + 1
  )} ${date.getFullYear()}`;
};

const getMonthName = (month: number) => {
  let name: string = '';
  switch (month) {
    case 1:
      name = 'Januar';
      break;
    case 2:
      name = 'Februar';
      break;
    case 3:
      name = 'Mars';
      break;
    case 4:
      name = 'April';
      break;
    case 5:
      name = 'Mai';
      break;
    case 6:
      name = 'Juni';
      break;
    case 7:
      name = 'Juli';
      break;
    case 8:
      name = 'August';
      break;
    case 9:
      name = 'September';
      break;
    case 10:
      name = 'Oktober';
      break;
    case 11:
      name = 'November';
      break;
    case 12:
      name = 'Desember';
      break;
  }
  return name;
};
