import React from 'react';
import styled from 'styled-components';

const AdsStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  width: 100%;
  justify-content: center;
  /*   padding-top: 3em; */

  h2 {
    font-size: 2em;
    @media all and (min-width: 400px) {
      font-size: 3em;
    }

    @media all and (min-width: 600px) {
      font-size: 5em;
    }
  }

  .card-split {
    .content {
      row-gap: 30%;
    }
  }

  @media all and (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    /*     padding-top: 3em; */
    h2 {
      font-size: 3em;
    }
  }

  @media all and (min-width: 1140px) {
    grid-template-columns: repeat(3, 1fr);
    /*     padding-top: 3em; */
    h2 {
      font-size: 2em;
    }
  }
`;

const Ads: React.FC<{ style?: any }> = ({ children, style }) => {
  return <AdsStyle style={style}>{children}</AdsStyle>;
};

export default Ads;
