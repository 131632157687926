import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Range, getTrackBackground } from 'react-range';
import { currency } from '../../../../utils/format/currency';

interface IArgs {
  minValue: number;
  maxValue: number;
  stepValue: number;
  title: string;
  initValue: number;
  valueDesc?: string;
  isCurrency?: boolean;
  onChange?: (value: number) => void;
}

const Container = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  font-weight: bold;
`;

const Value = styled.div`
  display: flex;
  justify-content: end;
`;

const Track = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.colors.primary};
`;

const Thumb = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.gray};
  border: 4px solid ${({ theme }) => theme.colors.primary};
`;

const Slider = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IRangeArgs {
  min: number;
  max: number;
  step: number;
}

export const RangeSlider: React.FC<IArgs> = ({
  minValue,
  maxValue,
  stepValue,
  initValue,
  title,
  valueDesc,
  isCurrency,
  onChange
}) => {
  const [value, SetValue] = useState(initValue ?? 0);
  const [trackColor, SetTrackColor] = useState('');
  const [range, SetRange] = useState<IRangeArgs>({
    max: maxValue,
    min: maxValue,
    step: stepValue
  });

  useEffect(() => {
    SetValue(initValue ?? 0);
    //SetTrackColor(getTrackColor([initValue]));
  }, [initValue]);

  useEffect(() => {
    SetRange({
      max: maxValue,
      min: minValue,
      step: stepValue
    });
    //SetTrackColor(getTrackColor([initValue]));
  }, [minValue, maxValue, stepValue]);

  useEffect(() => {
    SetTrackColor(getTrackColor([initValue]));
  }, []);

  useEffect(() => {
    SetTrackColor(getTrackColor([initValue]));
  }, [initValue, range]);

  const getTrackColor = (values: number[]) => {
    return getTrackBackground({
      min: minValue,
      max: maxValue,
      colors: ['#E7CFA7', '#302d2e'],
      values
    });
  };

  const onSendValue = (value: number) => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  return (
    <Container>
      <Info>
        <Title>{title}</Title>
        <Value>
          {isCurrency
            ? currency({ number: value, decimals: 0, seperator: ' ' })
            : value}
          {valueDesc ? ` ${valueDesc}` : null}
        </Value>
      </Info>
      {trackColor?.length ? (
        <Slider>
          <Range
            step={range.step}
            min={range.min}
            max={range.max}
            values={[value]}
            onChange={(values) => {
              if (values?.length) {
                SetValue(values[0]);
                //onSendValue(values[0]);
              }
              SetTrackColor(getTrackColor(values));
            }}
            onFinalChange={(values) => {
              onSendValue(value);
            }}
            renderTrack={({ props, children }) => (
              <Track
                {...props}
                style={{
                  ...props.style,
                  ...{
                    background: trackColor
                  }
                }}
              >
                {children}
              </Track>
            )}
            renderThumb={({ props }) => (
              <Thumb {...props} style={{ ...props.style }} />
            )}
          />
        </Slider>
      ) : null}
    </Container>
  );
};
