/*
rate = interest rate per period. renten for året (nominell rente / antall betalinger)
NPr = total number of payment periods in the annuity
pv = present value (lånet)
fv = future value. Det du ønsker å sitte igjen med etter å ha utførst siste betaling. Default = 0
due = Due Date. Når skal betalingen skje?
*/
export enum DueDate {
  Beginning,
  End
}
export const pmt = (
  rate: number,
  NPr: number,
  pv: number,
  fv?: number,
  due?: DueDate
) => {
  if (!rate) {
    return -(pv + (fv ?? 0)) / NPr;
  }

  const x = Math.pow(1 + rate, NPr);
  let pmt = -((rate * (pv * x + (fv ?? 0))) / (x - 1));

  if (due === DueDate.Beginning) {
    return pmt / (1 + rate);
  }

  return pmt;
};
