import { minEquity } from './minEquity';
import { effectiveRate, effectiveRatePerYearWithFees } from './effectiveRate';
import { calc } from './calc';
import { pmt } from './pmt';
import { plan } from './plan';
import { calcIRR } from './irr';

export const loanUtil = {
  minEquity,
  effectiveRate,
  effectiveRatePerYearWithFees,
  calc,
  pmt,
  plan,
  calcIRR
};
