import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface IArgs {
  onClick?: any;
  isPositive: boolean;
  className?: string;
  height?: number;
  width?: number;
}

export const OpenButton: React.FC<IArgs> = ({
  isPositive,
  onClick,
  className,
  height,
  width
}) => {
  return (
    <Wrapper
      className={`${className} ${isPositive ? `` : `rotate`}`}
      style={{ width: width, height: height }}
      onClick={onClick}
    >
      <StyledIcon className={isPositive ? 'hide' : undefined}>
        <svg
          width={width ? width : 22}
          height={height ? height : 23}
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7716 11.522C21.7716 17.3461 17.0502 22.0675 11.2261 22.0675C5.40203 22.0675 0.680664 17.3461 0.680664 11.522C0.680664 5.69792 5.40203 0.976562 11.2261 0.976562C17.0502 0.976562 21.7716 5.69792 21.7716 11.522ZM2.11651 11.522C2.11651 16.5531 6.19502 20.6316 11.2261 20.6316C16.2572 20.6316 20.3357 16.5531 20.3357 11.522C20.3357 6.49092 16.2572 2.41241 11.2261 2.41241C6.19502 2.41241 2.11651 6.49092 2.11651 11.522Z"
            fill="#4B4B4B"
          />
          <path
            d="M14.7412 10.9017V12.5559L7.29736 12.5559L7.29736 10.9017L14.7412 10.9017Z"
            fill="#4B4B4B"
          />
        </svg>
      </StyledIcon>
      <StyledIcon className={isPositive ? undefined : 'hide'}>
        <svg
          width={width ? width : 22}
          height={height ? height : 22}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7716 10.5455C21.7716 16.3696 17.0502 21.0909 11.2261 21.0909C5.40203 21.0909 0.680664 16.3696 0.680664 10.5455C0.680664 4.72136 5.40203 0 11.2261 0C17.0502 0 21.7716 4.72136 21.7716 10.5455ZM2.11651 10.5455C2.11651 15.5766 6.19502 19.6551 11.2261 19.6551C16.2572 19.6551 20.3357 15.5766 20.3357 10.5455C20.3357 5.51436 16.2572 1.43584 11.2261 1.43584C6.19502 1.43584 2.11651 5.51436 2.11651 10.5455Z"
            fill="url(#paint0_linear_823_1170)"
          />
          <path
            d="M10.1922 7.03027H11.8464V14.4741H10.1922V7.03027Z"
            fill="url(#paint1_linear_823_1170)"
          />
          <path
            d="M14.7412 9.9251V11.5793H7.29736L7.29736 9.9251L14.7412 9.9251Z"
            fill="url(#paint2_linear_823_1170)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_823_1170"
              x1="8.16058"
              y1="31.9"
              x2="11.6974"
              y2="0.0523354"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#834628" />
              <stop offset="0.34375" stopColor="#824528" />
              <stop offset="0.572917" stopColor="#D7A87C" />
              <stop offset="0.791667" stopColor="#9C603F" />
              <stop offset="1" stopColor="#9A5E3D" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_823_1170"
              x1="9.93733"
              y1="18.2891"
              x2="11.1856"
              y2="7.04874"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#834628" />
              <stop offset="0.34375" stopColor="#824528" />
              <stop offset="0.572917" stopColor="#D7A87C" />
              <stop offset="0.791667" stopColor="#9C603F" />
              <stop offset="1" stopColor="#9A5E3D" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_823_1170"
              x1="9.93733"
              y1="18.2891"
              x2="11.1856"
              y2="7.04874"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#834628" />
              <stop offset="0.34375" stopColor="#824528" />
              <stop offset="0.572917" stopColor="#D7A87C" />
              <stop offset="0.791667" stopColor="#9C603F" />
              <stop offset="1" stopColor="#9A5E3D" />
            </linearGradient>
          </defs>
        </svg>
      </StyledIcon>
    </Wrapper>
  );
};

export const testButton: React.FC = () => {
  const [open, SetOpen] = useState(false);

  const onClick = () => {
    SetOpen(!open);
  };

  return <OpenButton isPositive={open} onClick={onClick} />;
};

const Wrapper = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
  overflow: hidden;
`;

const StyledIcon = styled.div`
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
  position: absolute;

  &.hide {
    opacity: 0;
  }
`;
