import { calcIRR } from './irr';

interface IArgsSimple {
  rate: number;
  terms: number;
}

/*
interface IArgsAdvanced {
  loan: number;
  rate: number;
  duration: number;
  termsPrYear: number;
  feePrTerm?: number;
  feeEstablish?: number;
}*/

export const effectiveRate = (args: IArgsSimple) => {
  const { rate, terms } = args;
  if (!rate || !terms)
    throw new Error('effectiveRate Simple: Arguments are invalid!');
  return Math.pow(1 + rate / terms, terms) - 1;
};

/*
export const effectiveRateAdvanced = (args: IArgsAdvanced) => {
  const { loan, rate, duration, termsPrYear, feePrTerm, feeEstablish } = args;
  if (!rate || !loan || !duration || !termsPrYear)
    throw new Error('effectiveRate Advanced: Arguments are invalid!');

  const x = loan * (rate > 1 ? rate / 100 : rate);
  const feePrYear =
    (feePrTerm && feePrTerm > 0 ? feePrTerm * termsPrYear : 0) +
    (feeEstablish && feeEstablish > 0 ? feeEstablish / duration : 0);

  return (x + feePrYear) / loan;
};*/

interface IAgrsPrYear {
  loan: number;
  totalPeriods: number;
  paymentPrMonth: number;
}

export const effectiveRatePerYearWithFees = (args: IAgrsPrYear) => {
  const { loan, totalPeriods, paymentPrMonth } = args;
  if (!loan || !totalPeriods || totalPeriods < 0 || !paymentPrMonth)
    throw new Error('effectiveRate Pr Year: Arguments are invalid!');
  const cashFlow = new Array<number>(totalPeriods);
  cashFlow.fill(paymentPrMonth > 0 ? -paymentPrMonth : paymentPrMonth, 0);
  const irr = calcIRR([...[loan > 0 ? loan : loan * -1], ...cashFlow]);
  return Math.pow(1 + irr, 12) - 1;
};
