import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CalcStatus,
  IResult as ICalcResult
} from '../../../../utils/calculator/loan/types';
import { loanUtil } from '../../../../utils/calculator/loan';
import { currency } from '../../../../utils/format/currency';
import { routeConfig } from '../../../../__config__/routes';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { RangeSlider } from './RangeSlider';
import { LogoNordea } from './LogoNordea';

interface IUserArgs {
  value: number;
  equity: number;
  duration: number;
  rate: number;
}

interface IBank {
  //nominalRate: number;
  feeEstablished: number;
  feePrMonth: number;
  maxEquity: number;
  termsPrYear: number;
}

const Calculator: React.FC = () => {
  const [loading, SetLoading] = useState(true);
  const [bank] = useState<IBank>({
    feeEstablished: 3200,
    feePrMonth: 65,
    //nominalRate: 2.5 / 100,
    maxEquity: 100000,
    termsPrYear: 12
  });
  const [calc, SetCalc] = useState<IUserArgs>({
    value: 3800000,
    duration: 25,
    equity: 1140000,
    rate: 4.5
  });
  const [minEquity, SetMinEquity] = useState(loanUtil.minEquity(calc.value));
  const [result, SetResult] = useState<ICalcResult>({
    status: CalcStatus.error,
    cost: 0,
    effectiveRate: 0,
    prMonth: 0,
    totalLoan: 0
  });

  useEffect(() => {
    CalcResult();
  }, [calc]);

  const CalcResult = () => {
    const res = loanUtil.calc({
      durationYears: calc.duration,
      loan: calc.value - calc.equity,
      nominalRate: calc.rate / 100,
      feeEstablish: bank.feeEstablished,
      feePrTerm: bank.feePrMonth,
      termsPrYear: bank.termsPrYear
    });
    SetResult(res);
    SetLoading(false);
  };

  const getNewEquity = (loan: number) => {
    const minEq = loanUtil.minEquity(loan);
    if (minEq > calc.equity) {
      return minEq;
    }
    if (calc.equity > loan) {
      return loan - bank.maxEquity;
    }
    return calc.equity;
  };

  const printPercentage = (value?: number) => {
    return value ? `${value.toFixed(2)} %` : ``;
  };

  const printCurrency = (value?: number) => {
    return value ? `${currency({ number: value, seperator: ' ' })} kr` : ``;
  };
  return (
    <>
      <Container>
        <LogoNordea className="logo" />
        {loading ? <Text className="loading">Loading...</Text> : null}
        <Wrapper className={loading ? '' : 'show'}>
          <Sliders>
            <RangeSlider
              minValue={2000000}
              maxValue={10000000}
              stepValue={25000}
              initValue={calc.value}
              isCurrency={true}
              onChange={(value: number) => {
                const eq = loanUtil.minEquity(value);
                SetCalc((prevState) => ({
                  ...prevState,
                  value: value,
                  equity: getNewEquity(value)
                }));
                SetMinEquity(eq);
              }}
              title="Kjøpesum"
              valueDesc="kr"
            />
            <RangeSlider
              minValue={minEquity}
              maxValue={calc.value - bank.maxEquity}
              stepValue={10000}
              initValue={calc.equity}
              title="Egenkapital"
              valueDesc="kr"
              isCurrency={true}
              onChange={(value: number) => {
                SetCalc((prevState) => ({
                  ...prevState,
                  equity: value
                }));
              }}
            />
            <RangeSlider
              minValue={10}
              maxValue={30}
              stepValue={5}
              initValue={calc.duration}
              title="Netbetalingstid"
              valueDesc="år"
              onChange={(value: number) => {
                SetCalc((prevState) => ({
                  ...prevState,
                  duration: value
                }));
              }}
            />
            <RangeSlider
              minValue={2}
              maxValue={8}
              stepValue={0.5}
              initValue={calc.rate}
              title="Nominell rente"
              valueDesc="%"
              onChange={(value: number) => {
                SetCalc((prevState) => ({
                  ...prevState,
                  rate: value
                }));
              }}
            />
          </Sliders>
          {result?.status === CalcStatus.success && result?.prMonth ? (
            <>
              <Text>Estimert pr. måned</Text>
              <Result>
                {currency({ number: result.prMonth, seperator: ' ' })} kr
              </Result>
              <Details>
                {result.effectiveRate ? (
                  <div>
                    {printPercentage(result.effectiveRate)} eff /{' '}
                    {printPercentage(calc.rate)} nom. rente
                  </div>
                ) : null}
                <div>
                  Lånesum:{' '}
                  {currency({
                    number: calc.value - calc.equity,
                    seperator: ' '
                  })}{' '}
                  kr
                </div>
              </Details>
              <Footer>
                <div>
                  Eff {printPercentage(result.effectiveRate)}.{' '}
                  {printCurrency(calc.value - calc.equity)} o/{calc.duration}{' '}
                  år.
                </div>
                <div>
                  Kostnad {printCurrency(result.cost)}. Totalt:{' '}
                  {printCurrency(result.totalLoan)}
                </div>
              </Footer>
            </>
          ) : null}
          <ButtonGroup style={{ justifyContent: 'center' }}>
            <Button
              className="button"
              type="primary"
              colorTheme="gold"
              target="_blank"
              as="a"
              href={routeConfig?.nordea_finansiering_3.url}
            >
              Få tilbud på boliglån
            </Button>
          </ButtonGroup>
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 2em 2em;
  background-color: #202020;
  align-self: end;
  min-width: 345px;
  display: flex;
  flex-direction: column;
  .logo {
    padding-left: 1em;
  }
  .button {
    text-align: center;
    width: 100%;
  }
  h2: {
    margin: 0;
  }
  div: {
    color: white;
  }
  .loading {
    font-size: 1.5em;
    text-align: center;
  }

  @media all and (max-width: 400px) {
    min-width: unset;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 0 1em;
  width: 100%;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  &.show {
    max-height: 100vh;
  }
`;

const Sliders = styled.div`
  width: 100%;
`;

const Result = styled.div`
  color: white;
  font-size: 3em;
  font-weight: bold;
  padding: 0.1em 0;
`;

const Text = styled.div`
  padding-top: 0.6em;
  color: white;
`;

const Details = styled.div`
  color: white;
  font-size: 12px;
`;

const Footer = styled.div`
  color: #646263;
  padding: 2em 0;
  font-size: 12px;
`;

export default Calculator;
