import { title } from 'process';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../ui/button/Button';
import { Heading } from '../../../ui/text/Heading';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Background = styled.img`
  width: 100%;
`;

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Logo2 = styled.div`
  width: 100%;
  height: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  padding: 2em;
  text-align: center;

  h2 {
    padding: 0;
    margin: 0;
  }

  .description {
  }

  .button {
    text-align: center;
    width: max-content;
  }
`;

interface IArgs {
  className?: string;
  background?: string;
  logo?: string;
  link?: string;
  button?: boolean;
  buttonText?: string;
  title?: string;
  description?: string;
}

const Card: React.FC<IArgs> = ({
  className,
  background,
  logo,
  link,
  button,
  buttonText,
  title,
  description
}) => {
  return (
    <Container className={className}>
      <a href={link} target="__blank">
        {background ? <Background src={background} /> : null}
        <Content className="content">
          {logo ? (
            <Logo2
              className="logo"
              style={{ backgroundImage: `url(${logo})` }}
            />
          ) : null}
          {title ? (
            <Heading tag="h2">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Heading>
          ) : null}
          {description ? (
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          ) : null}
          {button ? (
            <Button
              className="button"
              type="secondary"
              colorTheme="gold"
              as="a"
            >
              {buttonText}
            </Button>
          ) : null}
        </Content>
      </a>
    </Container>
  );
};

export default Card;
