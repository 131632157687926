import * as React from 'react';
import styled from 'styled-components';

interface IArgs {
  className?: string;
}

export const LogoNordea: React.FC<IArgs> = ({ className }) => {
  return (
    <Logo className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        xmlSpace="preserve"
        aria-label="Nordea logo"
        focusable="false"
        viewBox="0 0 136.1 28.6"
        fill="currentColor"
      >
        <g id="Lager_1">
          <path d="M124.1,6.2c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9c2.3,0,5.4-1.2,6.8-3.6v3.3h4.3V17.6    C136.2,10.6,130.6,5.7,124.1,6.2z M131.4,17.6c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2    c3.4,0,6.2,2.7,6.4,6.1L131.4,17.6L131.4,17.6z"></path>
          <path d="M100.5,6.2c-5.9,0.2-10.9,4.7-10.9,11.3c0,6,4.9,11.1,11.2,11.1c4.4,0,8.7-2.6,10.4-6.9l-4.3-1.3    c-0.8,1.8-2.7,3.3-4.8,3.7c-3,0.6-5.8-1.3-6.9-3.3l16.7-4.6C111.4,11.8,107.6,6,100.5,6.2z M94.3,16.8c0-2.3,1.4-5,4.4-6.1    c3.4-1.3,6.4,0.4,7.7,2.8L94.3,16.8z"></path>
          <path d="M63.8,10.7V6.1c-4.2,0-5.6,2.1-6.3,3.2V6.8h-4.8V17v11.1h4.9c0-6.6,0-6.5,0-10.5      C57.6,12.8,60.4,10.9,63.8,10.7z"></path>
          <polygon points="19.7,19.6 5.1,2.4 0.1,2.4 0.1,28.1 5.3,28.1 5.3,10.9 20.1,28.2 24.6,28.2 24.6,2.4 19.7,2.4"></polygon>
          <path d="M86.7,0.1h-4.6v8.4c-1.1-1.4-4.5-2.6-7.4-2.3c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9      c2.4,0,5.7-1.1,6.8-3.2v2.8h4.3V17.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L86.7,0.1L86.7,0.1z M81.9,17.6      c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2c3.4,0,6.2,2.7,6.4,6.1      C81.9,16.9,81.9,17.6,81.9,17.6z"></path>
          <path d="M38.7,6.1c-6.2,0-11.2,5-11.2,11.2s5,11.2,11.2,11.2s11.2-5,11.2-11.2C49.8,11.1,44.8,6.1,38.7,6.1z       M38.7,23.7c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4C45.1,20.9,42.2,23.7,38.7,23.7z"></path>
        </g>
      </svg>
    </Logo>
  );
};

const Logo = styled.div`
  height: 3em;
  padding-bottom: 1em;
  svg {
    display: block;
    fill: white;
    height: auto;
    max-height: 100%;
    path {
      fill: white;
    }
    :not(:root) {
      overflow: hidden;
    }
  }
`;
